import React from 'react';

const IconLoader = () => (
  <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.09584 23.9047L45.5 2.3094L82.9042 23.9047V67.0953L45.5 88.6906L8.09584 67.0953V23.9047Z"
      stroke="#65FFDA"
      strokeWidth="4"
    />
    <path d="M38.9052 31.68H43.9452V55.52H55.9852V60H38.9052V31.68Z" fill="#65FFDA" />
  </svg>
);

export default IconLoader;
